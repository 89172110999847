<template>
  <div class="projects" v-if="!$store.state.loading">
    <h1 data-aos="zoom-in" data-aos-duration="1000" v-html="$t('projects-title')"></h1>

    <div class="grid">
      <section class="project-grid">
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[2]" alt="CarboBrake ATS Corsa RR Turbo"/>
          <h3>{{ $t('projects-section2-main-title') }}</h3>
          <router-link to="/ats-corsa-rr-turbo">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[3]" alt="CarboBrake Kalmar Automotive"/>
          <h3>{{ $t('projects-section6-main-title') }}</h3>
          <router-link to="/kalmar-automotive">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[4]" alt="CarboBrake Nissan GT-R"/>
          <h3>{{ $t('projects-section7-main-title') }}</h3>
          <router-link to="/nissan-gt-r">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[5]" alt="CarboBrake Aznom Palladium"/>
          <h3>{{ $t('projects-section5-main-title') }}</h3>
          <router-link to="/aznom-palladium">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[6]" alt="CarboBrake Aspark Owl"/>
          <h3>{{ $t('projects-section3-main-title') }}</h3>
          <router-link to="/aspark-owl">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
        <div data-aos="fade-left" data-aos-duration="1000">
          <img :src="images[1]" alt="CarboBrake Alfa Romeo 155 DTM"/>
          <h3>{{ $t('projects-section4-main-title') }}</h3>
          <router-link to="/alfa-romeo-155-dtm">
            <button class="learn-more" style="left: 35px;top: 105px;">
              {{ $t("btn-learn-more") }}
            </button>
          </router-link>
        </div>
      </section>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/projectsreferences_cover.jpg'),
        require('../assets/projects-55.jpg'),
        require('../assets/projects-ats.jpg'),
        require('../assets/projects-kalmar.jpg'),
        require('../assets/projects-nissangtr.jpg'),
        require('../assets/projects-palladium.jpg'),
        require('../assets/projects-aspark.jpg'),
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"> </style>
